<template>
	<b-card>
		<!-- Card with Image -->
		<b-card body-class="text-center" class="mb-2">
			<b-card-text class="text-primary"> Edit business details </b-card-text>

			<b-row align-h="center" class="mt-4 mb-2">
				<b-card class="image-card" body-class="p-0">
					<b-img :src="storeImage" style="height: 100%" thumbnail fluid center />
				</b-card>
			</b-row>

			<!-- Remove or Change Image Link -->
			<a href="#" class="card-link" @click="onRemoveOrChangeLinkClicked">
				Remove / Change Logo
			</a>
		</b-card>

		<!-- FORM -->
		<b-form>
			<!-- Store Name -->
			<b-form-group label="Enter Store Name" label-for="storeName" :invalid-feedback="getErrorMessage(errors, 'storeName')" :state="getErrorState(errors, 'storeName')">
				<b-form-input :state="getErrorState(errors, 'storeName')" type="text" id="storeName" v-model="form.storeName" placeholder="Store Name" trim />
			</b-form-group>

			<template>
				<!-- Unique Store URL Name -->
				<b-form-group label="Unique URL path*" label-for="storeUrlName" :invalid-feedback="getErrorMessage(errors, 'storeUrlName')" :state="getErrorState(errors, 'storeUrlName')">
					<b-form-input :state="getErrorState(errors, 'storeUrlName')" type="text" id="storeUrlName" v-model="form.storeUrlName" placeholder="URL path" @input="filterStoreUrlName" trim />
				</b-form-group>
				<p>
					{{ `/${form.storeUrlName}` | customerUrl }}
				</p>
			</template>

			<!-- Contact -->
			<b-form-group label="Contact Number" label-for="mobile">
				<b-form-input id="mobile" v-model="mobile" placeholder="Contact Number" trim disabled />
			</b-form-group>

			<b-form-group>
				<b-form-checkbox v-model="form.autoWhatsappOrder" id="autoWhatsappOrder" name="autoWhatsappOrder">
					Auto Whatsapp order
				</b-form-checkbox>
			</b-form-group>

			<b-row class="mt-4">
				<b-col>
					<b-button block pill variant="primary" @click="onSubmit">
						Submit
					</b-button>
				</b-col>
				<b-col>
					<b-button block pill @click="onCancel">Cancel</b-button>
				</b-col>
			</b-row>
		</b-form>

		<!-- Remove or Change Image Modal -->
		<b-modal v-model="showChangeImageModal" @hidden="resetModal" centered hide-header hide-footer>
			<!-- Image Uploader -->
			<image-uploader id="selectionImage" :debug="0" :maxWidth="350" :maxHeight="200" :quality="1" outputFormat="blob" :preview="false" accept="image/*" doNotResize="['gif', 'svg']" @input="onFileSelected" />

			<!-- Change Button -->
			<b-button v-if="storeImageForm.storeImage" variant="primary" class="mt-3" block @click="onChangeLogoClicked">
				Change Logo
			</b-button>

			<!-- REMOVE -->
			<b-button v-if="hasStoreImage" variant="danger" class="mt-3" block @click="onRemoveLogoClicked">
				Remove Logo
			</b-button>
		</b-modal>
	</b-card>
</template>

<script>
import Jimp from "jimp";

import { mapActions, mapGetters } from "vuex";

export default {
	mounted() {
		if (this.profile) {
			this.mobile = this.profile.mobile;
		}
		if (this.store) {
			this.form = {
				storeName: this.store.storeName,
				storeUrlName: this.store.storeUrlName,
				autoWhatsappOrder: !!this.store.autoWhatsappOrder,
			};
		}
	},
	data() {
		return {
			form: {
				storeName: null,
				storeUrlName: "",
				storeImage: null,
				autoWhatsappOrder: false,
			},
			storeImageForm: {
				storeImage: null,
			},
			errors: null,

			mobile: null,
			showChangeImageModal: false,
		};
	},
	computed: {
		...mapGetters({
			token: "login/getToken",
			store: "merchant/store/getStore",
			profile: "profile/getProfile",
			appShortLogo: "getAppShortLogo",
		}),
		storeExists() {
			if (this.store) {
				return true;
			}
			return false;
		},
		storeImage() {
			if (this.hasStoreImage) {
				return this.store.storeImage.blobUrl;
			}
			return this.appShortLogo;
		},
		hasStoreImage() {
			if (this.store && this.store.storeImage && this.store.storeImage.blobUrl) {
				return true;
			}
			return false;
		},
	},
	methods: {
		...mapActions({
			updateStoreData: "merchant/store/updateStoreData",
			updateStoreImage: "merchant/store/updateStoreImage",
			removeStoreImage: "merchant/store/removeStoreImage",
			addBlobs: "blobs/addBlobs",
		}),
		onSubmit() {
			this.errors = null;
			// if store exists, updating store
			if (this.storeExists) {
				this.updateStoreData(this.form)
					.then(() => {
						this.onCancel();
					})
					.catch(({ data }) => {
						this.errors = data.errors;
					});
			}
		},
		onRemoveOrChangeLinkClicked() {
			this.showChangeImageModal = true;
		},
		resetModal() {
			// on modal close, reset image
			this.onResetImage();
		},
		onFileSelected(file) {
			this.compressFile(file).then((imageFile) => {
				this.storeImageForm.storeImage = imageFile;
			});
		},
		onResetImage() {
			this.storeImageForm.storeImage = null;
		},
		onChangeLogoClicked() {
			// api call to update only image
			const file = this.storeImageForm.storeImage;
			this.updateStoreImage(file).then(() => {
				this.showChangeImageModal = false;
			});
		},
		onRemoveLogoClicked() {
			this.removeStoreImage().then(() => {
				this.showChangeImageModal = false;
			});
		},
		onCancel() {
			this.$router.go(-1);
		},

		filterStoreUrlName(value) {
			var storeUrlName = value;
			// add code, to trim all white spaces,
			storeUrlName = storeUrlName.replace(/\s/g, "");
			// remove special characters
			storeUrlName = storeUrlName.replace(/[^a-zA-Z0-9]/g, "");
			// convert all to lower case
			storeUrlName = storeUrlName.toLowerCase();
			// trimming again for safety
			storeUrlName = storeUrlName.trim();
			this.form.storeUrlName = storeUrlName;
		},

		async compressFile(file) {
			const buffer = await this.fileToBufferArray(file);
			const image = await Jimp.read(buffer);
			const jimpImage = image.quality(60);

			const array = await jimpImage.getBufferAsync(Jimp.MIME_JPEG);
			return new Blob([array], { type: Jimp.MIME_JPEG });
		},
	},
};
</script>

<style scoped>
.image-card {
	height: 90px;
}
</style>
